<template>
  <div>
    <div class="columns is-multiline mt-3 mr-2">
      <div class="column"
        v-show="hasSelected">
        Please select category:
        <div class="select">
          <select v-model="attachmentCategory">
            <option v-for="(m) in attachmentCategories"
              :key="m.quoteAttachmentCategoryId"
              :value="m.quoteAttachmentCategoryId">{{ m.name }}</option>
          </select>
        </div>
        <a v-show="hasSelected"
          class="button tooltip ml-2"
          @click="copyAttachmentToQuote(true)"
          data-tooltip="Copy to quote">
          <span class="icon has-text-primary">
            <i class="mdi mdi-content-copy mdi-24px" />
          </span>
        </a>
      </div>
      <div class="column is-narrow"
        :class="[{ 'is-full': !hasSelected }]">
        <p class="buttons is-justify-content-right">

          <!-- <a v-show="hasSelected"
            class="button tooltip"
            @click="openEmailModal()"
            data-tooltip="Email">
            <span class="icon">
              <i class="mdi mdi-email-fast mdi-24px" />
            </span>
          </a> -->
          <a class="button tooltip"
            @click="setSelectedStatusAll(true)"
            v-show="isIncoming"
            data-tooltip="Select all">
            <span class="icon has-text-success">
              <i class="mdi mdi-select-all mdi-24px" />
            </span>
          </a>
          <a v-show="hasSelected"
            class="button tooltip"
            @click="setSelectedStatusAll(false)"
            data-tooltip="Deselect all">
            <span class="icon has-text-grey">
              <i class="mdi mdi-select-off mdi-24px" />
            </span>
          </a>
        </p>

      </div>
    </div>
    <div>
      <div class="columns is-multiline ">
        <div v-for="attachment in innerAttachments"
          class="column is-1 m-0 p-1"
          :key="attachment.quoteEmailAttachmentId">
          <div class="is-clickable is-unselectable has-background-light py-0 px-0">
            <div class="columns is-gapless  is-relative">
              <div class="button is-primary is-small px-1 m-0 "
                v-show="isIncoming"
                @click="()=>{ attachment.isSelected = !attachment.isSelected }"
                :class="{'is-light': !attachment.isSelected}"
                style="position:absolute; top: 0; left: 0; z-index: 1">
                <i class="mdi mdi-check"
                  :class="{'':attachment.isSelected,'has-text-grey-light':!attachment.isSelected}" />
              </div>

              <div class="column is-full is-clipped">
                <figure class="image is-3by4 is-fullwidth is-vcentered"
                  @click="viewImage(attachment, attachment.isImage)">
                  <img v-if="attachment.isImage"
                    style="object-fit: cover"
                    :src="attachment.externalStorageURL">
                  <img alt="Vue logo"
                    v-if="attachment.isPdf"
                    src="../../../assets/images/attachment-pdf.png">
                  <img alt="Vue logo"
                    v-if="!attachment.isImage && !attachment.isPdf"
                    src="../../../assets/images/attachment-doc.png">
                </figure>
                <div class="columns is-multiline column is-clipped p-0 m-0">
                  <div class="column is-full p-0 m-0"
                    style="overflow:hidden;text-overflow: ellipsis; font-weight: bold; white-space: nowrap;"
                    :tooltip="attachment.filename"
                    :title="attachment.filename">{{ attachment.filename }})</div>
                </div>
              </div>

            </div>
          </div>
          <quote-email-attachment-viewer-modal :active="isViewerModalOpen"
            v-if="isViewerModalOpen"
            :attachment="viewAttachment"
            :quote-id="quoteId"
            @cancel="viewImage(null)"
            :is-incoming="isIncoming" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import QuoteEmailAttachmentViewerModal from './QuoteEmailAttachmentViewerModal.vue'
import QuoteService from '@/views/quote/QuoteService'
//import { DateTime } from 'luxon'
import { EmailService } from '@/services'
import { EventHubTypes } from '@/enums'

export default {
  name: 'QuoteEmailAttachments',
  filters: {},
  components: { QuoteEmailAttachmentViewerModal },
  props: {
    quoteId: {
      type: String
    },
    emailId: {
      type: String
    },
    // quoteAttachmentId: {
    //   type: String
    // },
    // quoteAttachmentCategoryId: {
    //   type: String
    // },
    attachments: {
      type: Array,
      default: () => []
    },
    // attachment: {
    //   type: Object
    // },
    // categories: {
    //   type: Array,
    //   default: () => []
    // }
    isIncoming: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalActive: false,
      isViewerModalOpen: false,
      attachmentUrl: '',
      innerAttachments: [],
      selectedQuoteEmailAttachmentId: '',
      viewAttachment: null,
      attachmentCategories: [],
      attachmentCategory: '',
      isCopyingAttachmentToQuote: false
    }
  },
  computed: {
    hasSelected() {
      return this.innerAttachments.filter((i) => i.isSelected).length > 0
    },
    selectedAttachments() {
      return this.innerAttachments.filter((i) => i.isSelected)
    }
  },
  watch: {
    emailId: {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        this.getAttachment()
      }
    }
  },
  async created() {
    await this.getAttachment()
    await this.loadQuoteAttachmentCategories()
  },
  mounted() {},
  methods: {
    async loadQuoteAttachmentCategories() {
      this.attachmentCategories = await QuoteService.getQuoteAttachmentCategories()
      if (!this.attachmentCategory) {
        this.attachmentCategory = this.attachmentCategories[0].quoteAttachmentCategoryId
      }
    },
    async getAttachment() {
      const attachments = await EmailService.getQuoteEmailAttachments(this.quoteId, this.emailId, this.isIncoming)
      this.innerAttachments = attachments
    },
    setSelectedStatusAll(value) {
      this.innerAttachments.forEach(function (item) {
        item.isSelected = value
      })
    },
    openModal(attachment) {
      const filename = attachment.filename
      //const decodeData = this.decodeData(attachment.ImageContentBase64)
      if (filename.toLowerCase().endsWith('.pdf')) {
        this.attachmentUrl = `data:application/pdf;base64,${attachment.imageContentBase64}`
      } else {
        this.attachmentUrl = `data:image/jpeg;base64,${attachment.imageContentBase64}`
      }
      this.isModalActive = true
    },
    decodeData(base64Data) {
      return Buffer.from(base64Data, 'base64')
    },
    closeModal() {
      this.isModalActive = false
      this.$emit('close')
    },
    handleIsSelectedChange(value) {
      let isSelected = value
      //this.attachment.isSelected = isSelected
      //console.log('sending update:attachment', isSelected)
      this.$emit('update:attachment', { attachment: this.attachment, isSelected: isSelected })
    },
    imageUpdated() {
      this.$emit('image-uploaded')
    },
    downloadUrl(url, fileName) {
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async downloadModifiedDocument(attachment) {
      const response = await EmailService.downloadModifiedQuoteEmailAttachment(this.quoteId, attachment.quoteEmailAttachmentId, attachment.isIncoming)
      let fileName = attachment.filename
      // if (fileName.indexOf('.pdf') === -1) {
      //   fileName += '.pdf'
      // }
      console.log(response)
      if (attachment.isPdf) {
        this.downloadUrl('data:application/pdf;base64,' + response.data, fileName)
      } else if (this.hasExtension(fileName, '.xml')) {
        this.downloadUrl('data:application/xml;base64,' + response.data, fileName)
      } else {
        this.downloadUrl('data:application/text/plain;base64,' + response.data, fileName)
      }
    },
    hasExtension(fileName, extension) {
      return fileName.toLowerCase().endsWith(extension.toLowerCase())
    },
    async viewImage(attachment, isImage) {
      console.log(attachment)
      if (attachment) {
        if (!isImage) {
          this.downloadModifiedDocument(attachment)
        } else {
          this.isViewerModalOpen = true
          this.selectedQuoteEmailAttachmentId = attachment.quoteEmailAttachmentId
          this.viewAttachment = attachment
        }
      } else {
        this.isViewerModalOpen = false
        this.selectedQuoteEmailAttachmentId = ''
        this.viewAttachment = null
      }
    },
    async copyAttachmentToQuote() {
      this.$showSpinner('Copying attachment')
      for (let row of this.selectedAttachments) {
        await EmailService.copyAttachmentToQuote(row.quoteEmailAttachmentId, this.quoteId, this.attachmentCategory)
      }
      this.$hideSpinner('Copying images')
      var response = await QuoteService.getQuoteAttachments(this.quoteId)
      const data = response.data
      let documentCount = Object.values(data).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
      this.$eventHub.$emit(EventHubTypes.DocumentCountChanged, documentCount)

      this.$notification.openNotificationWithType('success','Copy to attachment', 'Save successful')
      //console.log('trigger')
      //this.toggleCopyAttachmentModal(false)
    }
  }
}
</script>